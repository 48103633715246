// import React from "react";
// import useWindowSize from "../../hooks/use-window-size";
// import { useTheme } from "../../providers/theme-provider";
// import { useTranslation } from "react-i18next";
// import spacings from "../../theme/spacings";
// import { assetImages } from "../../constants/assets";
// import { fontSizes } from "../../theme/font-sizes";
// import CtaButton from "../buttons/cta-button/cta-button";
// import useSmoothScroll from "../../hooks/use-smooth-scroll";

// const Help = () => {
//   const { width } = useWindowSize();
//   const isMobile = width < 1024;

//   const { colors } = useTheme();

//   const { t } = useTranslation();

//   const paddingLeftRight = isMobile
//     ? spacings.leftRightSpacingMobile
//     : spacings.leftRightSpacing;

//   const paddingTopBottom = isMobile
//     ? spacings.topBottomSpacingMobile
//     : spacings.topBottomSpacing;

//   const handleScroll = useSmoothScroll();

//   return (
//     <div
//       style={{
//         padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
//       }}
//       className="flex justify-between items-center xl:gap-28 gap-8"
//     >
//       <div className="lg:block hidden min-w-[400px]">
//         <img src={assetImages.growthImage} alt="" width={360} className="" />
//       </div>

//       <div className="flex flex-col gap-4 ">
//         <h3
//           style={{
//             color: colors.mainHeading,
//             fontSize: fontSizes.heading2,
//           }}
//           className="font-semibold lg:text-start text-center"
//         >
//           {t("helpHead")}
//         </h3>
//         <p
//           style={{
//             color: colors.mainText,
//             fontSize: fontSizes.body,
//           }}
//           className="lg:text-start text-center"
//         >
//           {t("helpText")}
//         </p>
//         <div className="lg:mx-0 mx-auto lg:w-40">
//           <CtaButton
//             label={t("helpButton")}
//             style={{
//               padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
//               borderRadius: "6px",
//             }}
//             onClick={() => handleScroll("contact")}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Help;

import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { assetImages } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import CtaButton from "../buttons/cta-button/cta-button";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { motion } from "framer-motion";

const Help = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const handleScroll = useSmoothScroll();

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      className="flex justify-between items-center xl:gap-28 gap-8"
    >
      <div className="lg:block hidden min-w-[400px]">
        <motion.img
          src={assetImages.growthImage}
          alt=""
          width={360}
          className=""
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }} // Animates only once when in view
        />
      </div>

      <motion.div
        className="flex flex-col gap-4"
        whileInView={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: 50 }} // Start position
        transition={{ duration: 1 }}
        viewport={{ once: true }} // Animates only once when in view
      >
        <h3
          style={{
            color: colors.mainHeading,
            fontSize: fontSizes.heading2,
          }}
          className="font-semibold lg:text-start text-center"
        >
          {t("helpHead")}
        </h3>
        <p
          style={{
            color: colors.mainText,
            fontSize: fontSizes.body,
          }}
          className="lg:text-start text-center"
        >
          {t("helpText")}
        </p>
        <div className="lg:mx-0 mx-auto lg:w-40">
          <motion.div
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
          >
            <CtaButton
              label={t("helpButton")}
              style={{
                padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
                borderRadius: "6px",
              }}
              onClick={() => handleScroll("contact")}
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Help;
