const fontSizes = {
  logoText: "clamp(16px, 4vw, 18px)",
  heading1: "clamp(30px, 4vw, 72px)", // Scales between 32px and 48px
  ctaHeading: "clamp(26px, 4vw, 56px)", // Scales between 40px and 56px
  heading2: "clamp(23px, 2.5vw, 40px)", // Scales between 16px and 20px
  heading3: "clamp(20px, 2vw, 26px)", // Scales between 16px and 20px
  footerHeading: "clamp(18px, 2vw, 22px)", // Scales between 16px and 20px
  cardHeading: "clamp(14px, 1.2vw, 20px)", // Scales between 16px and 20px
  body: "clamp(14px, 1.5vw, 18px)", // Scales between 14px and 16px
  footerText: "clamp(13px, 1.5vw, 16px)", // Scales between 14px and 16px
  reviewName: "clamp(16px, 1.5vw, 18px)", // Scales between 14px and 16px
  cardBody: "clamp(16px, 1.5vw, 18px)", // Scales between 14px and 16px
  info: "clamp(11px, 1.5vw, 14px)", // Scales between 12px and 13px
  // Add more as needed
};

const smallFontSizes = {
  heading1: "34px",
  heading2: "20px",
  body: "14px",
  info: "13px",
  // Add more as needed
};

export { fontSizes, smallFontSizes };
