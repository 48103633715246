//MOBILE
import React from "react";
import Hero from "./hero/hero";

const HeaderSmall = () => {
  return (
    <div className="overflow-x-hidden relative">
      <Hero />
    </div>
  );
};

export default HeaderSmall;
