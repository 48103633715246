import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { fontSizes } from "../../theme/font-sizes";
import { assetImages } from "../../constants/assets";
import { motion } from "framer-motion";

const ClientSlider = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "ease",
    slidesToShow: 7,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientLogos = [
    assetImages.logoImage1,
    assetImages.logoImage2,
    assetImages.logoImage3,
    assetImages.logoImage4,
    assetImages.logoImage5,
    assetImages.logoImage6,
    assetImages.logoImage7,
  ];

  return (
    <div
      className="flex flex-col gap-4 items-center justify-center overflow-x-hidden"
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
    >
      {/* Animated Heading with whileInView */}
      <motion.h3
        style={{
          color: colors.mainHeading,
          fontSize: fontSizes.heading2,
        }}
        className="font-semibold"
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        initial={{
          opacity: 0,
          y: -20,
        }}
        transition={{
          duration: 0.8,
          ease: [0.42, 0, 0.58, 1],
        }}
        viewport={{ once: true }}
      >
        {t("topClientHead")}
      </motion.h3>

      {/* Animated Paragraph with whileInView */}
      <motion.p
        style={{
          color: colors.mainText,
          fontSize: fontSizes.body,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        initial={{
          opacity: 0,
          y: 20,
        }}
        transition={{
          duration: 0.8,
          ease: [0.42, 0, 0.58, 1],
        }}
        viewport={{ once: true }}
      >
        {t("topClientText")}
      </motion.p>

      <div className="w-full mt-6">
        <Slider {...settings}>
          {/* Animated Client Logos with whileInView */}
          {clientLogos.map((item, index) => (
            <motion.div
              key={index}
              className="flex items-center justify-center w-full"
              whileInView={{
                opacity: 1,
                x: 0,
              }}
              initial={{
                opacity: 0,
                x: 100,
              }}
              transition={{
                type: "spring",
                stiffness: 120,
                damping: 10,
                duration: 1,
              }}
              viewport={{ once: true }}
            >
              <img
                src={item}
                alt={`Client logo ${index}`}
                className="mx-auto w-16 sm:w-20"
              />
            </motion.div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSlider;
