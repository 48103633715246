import React from "react";
import { motion } from "framer-motion";
import useWindowSize from "../../../hooks/use-window-size";
import { useTheme } from "../../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import NavbarSmall from "../navbar/navbar-small";
import NavbarLarge from "../navbar/navbar-large";
import spacings from "../../../theme/spacings";
import { fontSizes } from "../../../theme/font-sizes";
import CtaButton from "../../buttons/cta-button/cta-button";
import { assetImages } from "../../../constants/assets";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";

const Hero = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacing
    : spacings.topBottomSpacingMobile;

  const handleScroll = useSmoothScroll();

  // Animation variants
  const textVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.2,
        ease: [0.42, 0, 0.58, 1], // Smooth easing
        staggerChildren: 0.3, // Staggered animation
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9, x: 50 },
    visible: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        duration: 1.5,
        ease: [0.42, 0, 0.58, 1], // Smooth easing
      },
    },
  };

  return (
    <motion.div
      className="xl:min-h-screen relative overflow-hidden"
      style={{
        backgroundColor: colors.heroColor,
      }}
      id="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      {isMobile ? <NavbarSmall /> : <NavbarLarge />}
      <div className="xl:min-h-[85vh] xl:flex xl:items-center">
        <div
          className="grid lg:grid-cols-2 grid-cols-1 lg:gap-3 place-items-center place-content-between"
          style={{
            padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
          }}
        >
          {/* Text Section */}
          <motion.div
            className="flex flex-col gap-6 lg:justify-start justify-center lg:items-start items-center"
            variants={textVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.h3
              className="font-bold lg:max-w-2xl max-w-[360px] lg:text-start text-center"
              style={{
                fontSize: fontSizes.heading1,
                color: colors.mainHeading,
              }}
              variants={childVariants}
            >
              <span
                style={{
                  color: colors.mainGreen,
                }}
              >
                {t("heroHeadingHighlight")}
              </span>
              {t("heroHeading")}
            </motion.h3>

            <motion.p
              style={{
                color: colors.mainText,
                fontSize: fontSizes.body,
              }}
              className="lg:text-start text-center lg:max-w-full max-w-xl"
              variants={childVariants}
            >
              {t("heroText")}
            </motion.p>

            <motion.div
              variants={childVariants}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <CtaButton
                label={t("heroButton")}
                style={{
                  padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
                  borderRadius: "6px",
                }}
                onClick={() => handleScroll("contact")}
              />
            </motion.div>
          </motion.div>

          {/* Image Section */}
          <motion.div
            className="lg:flex hidden justify-end w-full"
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          >
            <img src={assetImages.heroImage} alt="" width={500} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
