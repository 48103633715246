import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { fontSizes } from "../../theme/font-sizes";
import { assetImages } from "../../constants/assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

const Testimonials = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const testimonials = [
    {
      review: t("testimonial.test1.review"),
      name: t("testimonial.test1.name"),
      role: t("testimonial.test1.role"),
    },
    {
      review: t("testimonial.test2.review"),
      name: t("testimonial.test2.name"),
      role: t("testimonial.test3.role"),
    },
    {
      review: t("testimonial.test3.review"),
      name: t("testimonial.test3.name"),
      role: t("testimonial.test3.role"),
    },
    {
      review: t("testimonial.test4.review"),
      name: t("testimonial.test4.name"),
      role: t("testimonial.test4.role"),
    },
    {
      review: t("testimonial.test5.review"),
      name: t("testimonial.test5.name"),
      role: t("testimonial.test5.role"),
    },
    {
      review: t("testimonial.test6.review"),
      name: t("testimonial.test6.name"),
      role: t("testimonial.test6.role"),
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "ease",
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <motion.div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.mainGreen,
      }}
      className="flex flex-col gap-8 overflow-x-hidden"
      id="testimonial"
      whileInView={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <motion.h3
        style={{
          color: colors.background,
          fontSize: fontSizes.heading2,
        }}
        className="font-semibold text-center xl:max-w-2xl lg:max-w-lg max-w-sm mx-auto"
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -20 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        {t("testimonialHead")}
      </motion.h3>

      {isMobile ? (
        <Slider {...sliderSettings}>
          {testimonials.map((test, index) => (
            <div key={index}>
              <motion.div
                style={{
                  backgroundColor: colors.background,
                }}
                className="p-6 rounded-lg flex flex-col gap-6 mx-4"
                // whileInView={{ opacity: 1 }}
                // initial={{ opacity: 0 }}
                // transition={{ duration: 0.5 }}
                // viewport={{ once: true }}
              >
                <div>
                  <blockquote
                    style={{
                      fontSize: fontSizes.body,
                      color: colors.reviewText,
                    }}
                  >
                    {test.review}
                  </blockquote>
                </div>

                <div className="flex flex-row gap-4 items-center">
                  <div>
                    <img src={assetImages.user} alt="User" className="w-14" />
                  </div>

                  <div className="flex flex-col gap-1">
                    <h3
                      style={{
                        color: colors.testnameText,
                        fontSize: fontSizes.reviewName,
                      }}
                      className="font-semibold"
                    >
                      {test.name}
                    </h3>
                    <p
                      style={{
                        fontSize: fontSizes.info,
                        color: colors.reviewText,
                      }}
                    >
                      {test.role}
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="grid grid-cols-3 gap-6">
          {testimonials.map((test, index) => (
            <motion.div
              key={index}
              style={{
                backgroundColor: colors.background,
              }}
              className="p-6 rounded-lg flex flex-col gap-6"
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.5 },
              }}
            >
              <div>
                <blockquote
                  style={{
                    fontSize: fontSizes.body,
                    color: colors.reviewText,
                  }}
                >
                  {test.review}
                </blockquote>
              </div>

              <div className="flex flex-row gap-4 items-center">
                <div>
                  <img src={assetImages.user} alt="" className="w-16" />
                </div>

                <div className="flex flex-col gap-1">
                  <h3
                    style={{
                      color: colors.testnameText,
                      fontSize: fontSizes.body,
                    }}
                    className="font-semibold"
                  >
                    {test.name}
                  </h3>
                  <p
                    style={{
                      fontSize: fontSizes.info,
                      color: colors.reviewText,
                    }}
                  >
                    {test.role}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default Testimonials;
