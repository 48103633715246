import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { fontSizes } from "../../theme/font-sizes";
import { motion } from "framer-motion";

const Process = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const isLarge = width > 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const handleScroll = useSmoothScroll();

  const steps = [t("process1"), t("process2"), t("process3"), t("process4")];

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.mainGreen,
      }}
      className="flex flex-col gap-8"
    >
      <motion.h3
        style={{
          color: colors.background,
          fontSize: fontSizes.heading2,
        }}
        className="font-semibold text-center"
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1 },
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        viewport={{ once: true }}
      >
        {t("processHead")}
      </motion.h3>

      <motion.p
        style={{
          color: colors.background,
          fontSize: fontSizes.body,
        }}
        className="text-center"
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 1 },
        }}
        initial={{ opacity: 0, y: 20 }}
        viewport={{ once: true }}
      >
        {t("processText")}
      </motion.p>

      <div className="w-full max-w-6xl mx-auto px-4 py-12 text-white">
        <motion.div
          className="flex flex-col lg:flex-row items-start justify-between relative"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {steps.map((step, index) => (
            <motion.div
              key={step}
              className="flex flex-col lg:flex-row items-center w-full mb-8 lg:mb-0"
              variants={itemVariants}
            >
              <div className="relative flex flex-col gap-10 justify-center items-center w-full">
                {!isMobile && (
                  <div
                    className={`w-8 h-8 rounded-full  z-10`}
                    style={{
                      backgroundColor: colors.background,
                    }}
                  />
                )}
                {/* {index < steps.length - 1 && (
                  <div
                    className={`
                  ${isMobile ? "h-16 w-0.5 my-2" : "w-full h-0.5 mx-2"} 
                  border-2 border-dashed border-white 
                  ${
                    isMobile
                      ? "absolute top-8 left-1/2 transform -translate-x-1/2"
                      : ""
                  }
                `}
                  />
                )} */}
                <p
                  className={`text-center mt-4 md:mt-0 ${
                    isMobile ? "" : ""
                  }  lg:max-w-[180px]`}
                  style={{
                    fontSize: fontSizes.reviewName,
                  }}
                >
                  {step}
                </p>
                {isMobile && (index < 2 || (index >= 2 && index < 3)) && (
                  <div className=" h-12 w-0.5">
                    <div
                      className="h-full w-full"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom, #E0E0E0 50%, transparent 50%)",
                        backgroundSize: "100% 20px",
                      }}
                    />
                  </div>
                )}
                {/* {!isMobile && (index < 2 || (index >= 2 && index < 3)) && (
                  <div className="absolute top-3 -right-[35%] w-[68%] mx-auto h-0.5 overflow-hidden">
                    <div
                      className="w-full h-full mx-auto"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #E0E0E0 50%, transparent 50%)",
                        backgroundSize: "20px 100%",
                      }}
                    />
                  </div>
                )} */}

                {index < steps.length - 1 && (
                  <>
                    {/* Mobile: Horizontal Dashed Line */}
                    {!isMobile && (index < 2 || (index >= 2 && index < 3)) && (
                      <div className="absolute top-3 -right-[35%] w-[68%] mx-auto h-0.5 overflow-hidden">
                        <div
                          className="w-full h-full mx-auto"
                          style={{
                            backgroundImage:
                              "linear-gradient(to right, #E0E0E0 50%, transparent 50%)",
                            backgroundSize: "20px 100%",
                          }}
                        />
                      </div>
                    )}

                    {/* Large Screens: Vertical Dashed Line */}
                    {/* {isMobile && (
                      <div className="absolute top-12 left-4 right-4 h-full w-0.5">
                        <div
                          className="h-full w-full"
                          style={{
                            backgroundImage:
                              "linear-gradient(to bottom, #E0E0E0 50%, transparent 50%)",
                            backgroundSize: "100% 20px",
                          }}
                        />
                      </div>
                    )} */}
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Process;
