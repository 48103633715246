import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import HeaderSmall from "../../components/header/header-small";
import HeaderLarge from "../../components/header/header-large";
import ClientSlider from "../../components/client-slider/client-slider";
import Community from "../../components/community/community";
import Choose from "../../components/choose/choose";
import Stats from "../../components/stats/stats";
import Process from "../../components/process/process";
import Help from "../../components/help/help";
import Testimonials from "../../components/testimonials/testimonials";
import CommunityUpdate from "../../components/community-update/community-update";
import ContactForm from "../../components/contact-form/contact-form";
import FooterLarge from "../../components/footer/footer-large";

function HomePage() {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  return (
    <div>
      {isMobile ? <HeaderSmall /> : <HeaderLarge />}
      <ClientSlider />
      <Community />
      <Choose />
      <Stats />
      <Process />
      <Help />
      <Testimonials />
      <CommunityUpdate />
      <ContactForm />
      <FooterLarge />
    </div>
  );
}

export default HomePage;
