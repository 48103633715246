import React, { useState } from "react";
import spacings from "../../../theme/spacings";
import useWindowSize from "../../../hooks/use-window-size";
import { useTheme } from "../../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { assetIcons } from "../../../constants/assets";
import { fontSizes } from "../../../theme/font-sizes";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";
import CtaButton from "../../buttons/cta-button/cta-button";
import { motion, AnimatePresence } from "framer-motion";
import InlineSvg from "../../inline-svg/inline-svg";
import { svgColors } from "../../../theme/svg-colors";

const NavbarSmall = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = spacings.leftRightSpacingMobile;

  const paddingTopBottom = spacings.topBottomSpacingNavMobile;

  const navItems = [
    { label: t("navbar.navItems.home"), sectionId: "about" },
    { label: t("navbar.navItems.services"), sectionId: "features" },
    { label: t("navbar.navItems.testimonial"), sectionId: "testimonial" },
    { label: t("navbar.navItems.contact"), sectionId: "contact" },
  ];

  const handleScroll = useSmoothScroll();

  const menuVariants = {
    closed: {
      opacity: 0,
      y: -20,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
  };

  const menuItemVariants = {
    closed: { opacity: 0, y: -10 },
    open: { opacity: 1, y: 0 },
  };

  return (
    <>
      <div
        className="flex justify-between items-center gap-3"
        style={{
          padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        }}
      >
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => handleScroll("home")}
        >
          <InlineSvg
            url={assetIcons.footerLogo}
            pathColors={[
              { index: 0, color: svgColors.mainLogo },
              { index: 1, color: svgColors.mainLogo },
            ]}
            heightInp={38}
            widthInp={38}
          />
          <h3
            className="font-semibold"
            style={{
              fontSize: fontSizes.logoText,
              color: colors.logoColor,
            }}
          >
            {t("navbar.logo")}
          </h3>
        </div>

        {/* <nav className="" style={{}}>
        <ul className="flex items-center gap-12">
          {navItems.map((item, index) => (
            <motion.li
              whileHover={{
                color: colors.mainGreen,
              }}
              key={index}
              onClick={() => handleScroll(item.sectionId)}
              className="cursor-pointer"
              style={{
                color: colors.navColor,
              }}
            >
              {item.label}
            </motion.li>
          ))}
        </ul>
      </nav> */}

        <div
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="cursor-pointer"
        >
          <InlineSvg
            url={assetIcons.menuBar}
            pathColors={[{ index: 0, color: svgColors.blackIcon }]}
            heightInp={26}
            widthInp={26}
          />
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="absolute top-0 left-0 right-0 bg-white shadow-lg z-50 rounded-b-lg overflow-hidden"
            style={{ backgroundColor: colors.backgroundColor }}
          >
            <nav className="py-3 px-6">
              <div className="flex justify-between items-center py-2 mb-2">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    handleScroll("home");
                    setIsMenuOpen(false);
                  }}
                >
                  <InlineSvg
                    url={assetIcons.footerLogo}
                    pathColors={[
                      { index: 0, color: svgColors.mainLogo },
                      { index: 1, color: svgColors.mainLogo },
                    ]}
                    heightInp={38}
                    widthInp={38}
                  />
                  <h3
                    className="font-semibold"
                    style={{
                      fontSize: fontSizes.logoText,
                      color: colors.logoColor,
                    }}
                  >
                    {t("navbar.logo")}
                  </h3>
                </div>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setIsMenuOpen(false)}
                  className="text-2xl"
                  style={{ color: colors.textColor }}
                >
                  <InlineSvg
                    url={assetIcons.close}
                    pathColors={[{ index: 0, color: svgColors.blackIcon }]}
                    heightInp={28}
                    widthInp={28}
                  />
                </motion.button>
              </div>
              <ul className="space-y-2">
                {navItems.map((item, index) => (
                  <motion.li
                    key={index}
                    variants={menuItemVariants}
                    transition={{ delay: index * 0.1 }}
                  >
                    <motion.button
                      whileHover={{ scale: 1.05, color: colors.mainGreen }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        handleScroll(item.sectionId);
                        setIsMenuOpen(false);
                      }}
                      className="text-base font-medium w-full text-left py-2  rounded-md transition-colors"
                      style={{ color: colors.navColor }}
                    >
                      {item.label}
                    </motion.button>
                  </motion.li>
                ))}
              </ul>
            </nav>
            <div className="py-2 px-6 ">
              <CtaButton
                label={t("navbar.ctaButton")}
                style={{
                  padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
                  borderRadius: "6px",
                }}
                onClick={() => handleScroll("contact")}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavbarSmall;
