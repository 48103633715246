import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { assetIcons } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { Link } from "react-router-dom";
import { socialRedirectUrls, urls } from "../../constants/urls";
import InlineSvg from "../inline-svg/inline-svg";
import { svgColors } from "../../theme/svg-colors";
import { motion } from "framer-motion";

const FooterLarge = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const handleScroll = useSmoothScroll();

  return (
    <motion.div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.logoColor,
      }}
      className="flex justify-between items-start gap-4 lg:flex-nowrap flex-wrap overflow-x-hidden"
      whileInView={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }} // Animation happens only once when in view
    >
      <motion.div
        className="flex flex-col gap-10 lg:mb-0 mb-8"
        whileInView={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }} // Animation happens only once when in view
      >
        <div
          className="flex flex-row gap-4 items-center cursor-pointer"
          onClick={() => handleScroll("home")}
        >
          <InlineSvg
            url={assetIcons.footerLogo}
            pathColors={[
              { index: 0, color: svgColors.socialIcon },
              { index: 1, color: svgColors.socialIcon },
            ]}
            heightInp={38}
            widthInp={38}
          />
          <h3
            className="font-semibold"
            style={{
              fontSize: fontSizes.footerHeading,
              color: colors.background,
            }}
          >
            {t("footerLogo")}
          </h3>
        </div>
        <div className="flex flex-col gap-2">
          <p
            style={{
              color: colors.heroColor,
              fontSize: fontSizes.footerText,
            }}
          >
            {t("footerText")}
          </p>
          <p
            style={{
              color: colors.heroColor,
              fontSize: fontSizes.footerText,
            }}
          >
            {t("footerText2")}
          </p>
        </div>

        <motion.div
          className="flex items-center gap-6"
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }} // Animation happens only once when in view
        >
          <Link
            to={socialRedirectUrls.instagram}
            target="__blank"
            className="p-3 rounded-full"
            style={{
              backgroundColor: colors.iconBg,
            }}
          >
            <InlineSvg
              url={assetIcons.instagram}
              pathColors={[
                { index: 0, color: svgColors.socialIcon },
                { index: 1, color: svgColors.socialIcon },
              ]}
              heightInp={26}
              widthInp={26}
            />
          </Link>
          <Link
            to={socialRedirectUrls.dribble}
            target="__blank"
            className="p-3 rounded-full"
            style={{
              backgroundColor: colors.iconBg,
            }}
          >
            <InlineSvg
              url={assetIcons.dribble}
              pathColors={[{ index: 0, color: svgColors.socialIcon }]}
              heightInp={26}
              widthInp={26}
            />
          </Link>
          <Link
            to={socialRedirectUrls.twitter}
            target="__blank"
            className="p-3 rounded-full"
            style={{
              backgroundColor: colors.iconBg,
            }}
          >
            <InlineSvg
              url={assetIcons.twitter}
              pathColors={[{ index: 0, color: svgColors.socialIcon }]}
              heightInp={26}
              widthInp={26}
            />
          </Link>
          <Link
            to={socialRedirectUrls.youtube}
            target="__blank"
            className="p-3 rounded-full"
            style={{
              backgroundColor: colors.iconBg,
            }}
          >
            <InlineSvg
              url={assetIcons.youtube}
              pathColors={[{ index: 0, color: svgColors.socialIcon }]}
              heightInp={26}
              widthInp={26}
            />
          </Link>
        </motion.div>
      </motion.div>

      <motion.div
        className="flex flex-row lg:justify-start justify-between lg:w-auto w-full xl:gap-20 md:gap-8 gap-4 items-start flex-wrap"
        whileInView={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }} // Animation happens only once when in view
      >
        <motion.div
          className="flex flex-col gap-6"
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }} // Animation happens only once when in view
        >
          <h3
            style={{
              fontSize: fontSizes.footerHeading,
              color: colors.background,
            }}
            className="font-semibold"
          >
            {t("company.companyHead")}
          </h3>

          <ul className="flex flex-col gap-3">
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("company.aboutUs")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("company.blog")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("company.contactUs")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("company.pricing")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("company.testimonials")}
            </Link>
          </ul>
        </motion.div>

        <motion.div
          className="flex flex-col gap-6"
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }} // Animation happens only once when in view
        >
          <h3
            style={{
              fontSize: fontSizes.footerHeading,
              color: colors.background,
            }}
            className="font-semibold"
          >
            {t("support.supportHead")}
          </h3>

          <ul className="flex flex-col gap-3">
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("support.helpCenter")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("support.terms")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("support.legal")}
            </Link>
            <Link
              to={urls.dijinx}
              target="__blank"
              style={{
                color: colors.background,
                fontSize: fontSizes.footerText,
              }}
              className="cursor-pointer"
            >
              {t("support.privacy")}
            </Link>
          </ul>
        </motion.div>

        <motion.div
          className="flex flex-col gap-6"
          whileInView={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        >
          <h3
            style={{
              fontSize: fontSizes.footerHeading,
              color: colors.background,
            }}
            className="font-semibold"
          >
            {t("upToDate.upToDateHead")}
          </h3>

          <div className="relative">
            <input
              type="email"
              placeholder={t("upToDate.placeholder")}
              className="p-3 rounded-lg"
              style={{
                backgroundColor: colors.iconBg,
              }}
            />

            <InlineSvg
              url={assetIcons.send}
              pathColors={[
                { index: 0, color: svgColors.sendIcon }, // First path
              ]}
              className="absolute right-4 top-4 cursor-pointer"
            />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default FooterLarge;
