import React, { useEffect, useState } from "react";

function InlineSvg({ pathColors = [], url, className, heightInp, widthInp }) {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    // Fetch the SVG content from the CDN URL
    fetch(url)
      .then((res) => res.text())
      .then((data) => {
        // Create a DOM element from the SVG string
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, "image/svg+xml");
        const svg = svgDoc.querySelector("svg");

        // const height = svg.getAttribute("height");
        // const width = svg.getAttribute("width");

        if (svg) {
          // Set or override height and width attributes
          if (heightInp) {
            svg.setAttribute("height", heightInp);
          }
          if (widthInp) {
            svg.setAttribute("width", widthInp);
          }

          // Update the fill color of specified paths
          const paths = svg.querySelectorAll("path");
          pathColors.forEach(({ index, color }) => {
            if (paths[index]) {
              paths[index].setAttribute(
                "fill",
                color || paths[index].getAttribute("fill")
              );
            }
          });

          // Store the modified SVG content in the state
          setSvgContent(svg.outerHTML);
        } else {
          console.error("No <svg> found in the provided URL");
        }
      })
      .catch((error) => console.error("Error fetching SVG:", error));
  }, [url, pathColors, heightInp, widthInp]);

  return (
    <div
      className={className}
      // Inject SVG content as HTML
      dangerouslySetInnerHTML={{ __html: svgContent }}
      // style={{ fill: svgColors[color] }}
    />
  );
}

export default InlineSvg;
