import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons } from "../../constants/assets";
import { motion } from "framer-motion";

const Community = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const cardItems = [
    {
      icon: assetIcons.digitalStrategy,
      title: t("communityCard.card1.title"),
      description: t("communityCard.card1.description"),
    },
    {
      icon: assetIcons.userExperience,
      title: t("communityCard.card2.title"),
      description: t("communityCard.card2.description"),
    },
    {
      icon: assetIcons.digitalDesign,
      title: t("communityCard.card3.title"),
      description: t("communityCard.card3.description"),
    },
    {
      icon: assetIcons.development,
      title: t("communityCard.card4.title"),
      description: t("communityCard.card4.description"),
    },
  ];

  return (
    // <div
    //   style={{
    //     padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
    //   }}
    //   className="flex flex-col gap-4 items-center justify-center"
    //   id="features"
    // >
    //   <h3
    //     style={{
    //       color: colors.mainHeading,
    //       fontSize: fontSizes.heading2,
    //     }}
    //     className="font-semibold max-w-2xl text-center"
    //   >
    //     {t("communityHead")}{" "}
    //     <span
    //       style={{
    //         color: colors.mainGreen,
    //       }}
    //     >
    //       {t("communityHeadHighlight")}
    //     </span>
    //   </h3>

    //   <p
    //     style={{
    //       fontSize: fontSizes.body,
    //       color: colors.mainText,
    //     }}
    //   >
    //     {t("communityText")}
    //   </p>

    //   <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 min-[1400px]:gap-4 md:gap-2 gap-4 mt-5">
    //     {cardItems.map((item, index) => (
    //       <div className="p-4 rounded-lg shadow-sm flex flex-col gap-4 items-center justify-start">
    //         <div
    //           className="w-14 h-14 rounded-tl-lg rounded-tr-sm rounded-br-2xl rounded-bl-sm relative"
    //           style={{
    //             backgroundColor: colors.lightGreen,
    //           }}
    //         >
    //           <img
    //             src={item.icon}
    //             alt=""
    //             width={40}
    //             className="absolute -inset-3"
    //           />
    //         </div>

    //         <h3
    //           style={{
    //             color: colors.mainHeading,
    //             fontSize: fontSizes.heading3,
    //           }}
    //           className="font-semibold"
    //         >
    //           {item.title}
    //         </h3>

    //         <p
    //           style={{
    //             color: colors.mainText,
    //           }}
    //           className="text-center"
    //         >
    //           {item.description}
    //         </p>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      className="flex flex-col gap-4 items-center justify-center"
      id="features"
    >
      {/* Animated Heading */}
      <motion.h3
        style={{
          color: colors.mainHeading,
          fontSize: fontSizes.heading2,
        }}
        className="font-semibold max-w-2xl text-center"
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        initial={{
          opacity: 0,
          y: -20,
        }}
        transition={{
          duration: 0.8,
          ease: [0.42, 0, 0.58, 1],
        }}
        viewport={{ once: true }}
      >
        {t("communityHead")}{" "}
        <span
          style={{
            color: colors.mainGreen,
          }}
        >
          {t("communityHeadHighlight")}
        </span>
      </motion.h3>

      {/* Animated Paragraph */}
      <motion.p
        style={{
          fontSize: fontSizes.body,
          color: colors.mainText,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        initial={{
          opacity: 0,
          y: 20,
        }}
        transition={{
          duration: 0.8,
          ease: [0.42, 0, 0.58, 1],
        }}
        viewport={{ once: true }}
      >
        {t("communityText")}
      </motion.p>

      {/* Animated Cards */}
      <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 min-[1400px]:gap-4 md:gap-2 gap-4 mt-5">
        {cardItems.map((item, index) => (
          <motion.div
            key={index}
            className="p-4 rounded-lg shadow-sm flex flex-col gap-4 items-center justify-start"
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            initial={{
              opacity: 0,
              y: 20,
            }}
            transition={{
              duration: 0.8,
              delay: index * 0.2, // Staggering delay for cards
              ease: [0.42, 0, 0.58, 1],
            }}
            viewport={{ once: true }}
          >
            <div
              className="w-14 h-14 rounded-tl-lg rounded-tr-sm rounded-br-2xl rounded-bl-sm relative"
              style={{
                backgroundColor: colors.lightGreen,
              }}
            >
              <img
                src={item.icon}
                alt=""
                width={40}
                className="absolute -inset-3"
              />
            </div>

            <motion.h3
              style={{
                color: colors.mainHeading,
                fontSize: fontSizes.heading3,
              }}
              className="font-semibold"
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              initial={{
                opacity: 0,
                y: 20,
              }}
              transition={{
                duration: 0.8,
                ease: [0.42, 0, 0.58, 1],
              }}
              viewport={{ once: true }}
            >
              {item.title}
            </motion.h3>

            <motion.p
              style={{
                color: colors.mainText,
              }}
              className="text-center"
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              initial={{
                opacity: 0,
                y: 20,
              }}
              transition={{
                duration: 0.8,
                ease: [0.42, 0, 0.58, 1],
              }}
              viewport={{ once: true }}
            >
              {item.description}
            </motion.p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Community;
