import React from "react";
import useWindowSize from "../../hooks/use-window-size";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { assetImages } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import CtaButton from "../buttons/cta-button/cta-button";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { motion } from "framer-motion";

const Choose = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const handleScroll = useSmoothScroll();

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      className="flex justify-between items-center xl:gap-28 gap-8"
    >
      <div className="lg:block hidden min-w-[400px]">
        <motion.img
          src={assetImages.questionImage}
          alt=""
          width={360}
          className=""
          whileInView={{
            opacity: 1,
            x: 0,
          }}
          initial={{
            opacity: 0,
            x: -50,
          }}
          transition={{
            duration: 0.8,
            ease: [0.42, 0, 0.58, 1],
          }}
          viewport={{ once: true }}
        />
      </div>

      <div className="flex flex-col gap-4 ">
        <motion.h3
          style={{
            color: colors.mainHeading,
            fontSize: fontSizes.heading2,
          }}
          className="font-semibold lg:text-start text-center"
          whileInView={{
            opacity: 1,
            x: 0,
          }}
          initial={{
            opacity: 0,
            x: 20,
          }}
          transition={{
            duration: 0.8,
            ease: [0.42, 0, 0.58, 1],
          }}
          viewport={{ once: true }}
        >
          {t("chooseHeading")}
        </motion.h3>

        <motion.p
          style={{
            color: colors.mainText,
            fontSize: fontSizes.body,
          }}
          className="lg:text-start text-center"
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          initial={{
            opacity: 0,
            y: 20,
          }}
          transition={{
            duration: 0.8,
            ease: [0.42, 0, 0.58, 1],
          }}
          viewport={{ once: true }}
        >
          {t("chooseText")}
        </motion.p>

        <div className="lg:mx-0 mx-auto lg:w-40">
          <motion.div
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            initial={{
              opacity: 0,
              y: 20,
            }}
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.3 },
            }}
            transition={{
              duration: 0.8,
              delay: 0.2,
              ease: [0.42, 0, 0.58, 1],
            }}
            viewport={{ once: true }}
          >
            <CtaButton
              label={t("chooseButton")}
              style={{
                padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
                borderRadius: "6px",
              }}
              onClick={() => handleScroll("contact")}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
