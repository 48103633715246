import React from "react";
import spacings from "../../../theme/spacings";
import useWindowSize from "../../../hooks/use-window-size";
import { useTheme } from "../../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import { assetIcons } from "../../../constants/assets";
import { fontSizes } from "../../../theme/font-sizes";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";
import CtaButton from "../../buttons/cta-button/cta-button";
import { motion } from "framer-motion";
import { svgColors } from "../../../theme/svg-colors";
import InlineSvg from "../../inline-svg/inline-svg";

const NavbarLarge = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const navItems = [
    { label: t("navbar.navItems.home"), sectionId: "about" },
    { label: t("navbar.navItems.services"), sectionId: "features" },
    { label: t("navbar.navItems.testimonial"), sectionId: "testimonial" },
    { label: t("navbar.navItems.contact"), sectionId: "contact" },
  ];

  const handleScroll = useSmoothScroll();

  return (
    <motion.div
      className="flex justify-between items-center gap-3 overflow-x-hidden"
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => handleScroll("home")}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        {/* <img src={assetIcons.logo} alt="" /> */}
        <InlineSvg
          url={assetIcons.footerLogo}
          pathColors={[
            { index: 0, color: svgColors.mainLogo },
            { index: 1, color: svgColors.mainLogo },
          ]}
          heightInp={38}
          widthInp={38}
        />
        <h3
          className="font-semibold"
          style={{
            fontSize: fontSizes.logoText,
            color: colors.logoColor,
          }}
        >
          {t("navbar.logo")}
        </h3>
      </motion.div>

      <nav className="">
        <motion.ul
          className="flex items-center xl:gap-12 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.3,
              },
            },
          }}
        >
          {navItems.map((item, index) => (
            <motion.li
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
              whileHover={{
                color: colors.mainGreen,
              }}
              key={index}
              onClick={() => handleScroll(item.sectionId)}
              className="cursor-pointer"
              style={{
                color: colors.navColor,
              }}
            >
              {item.label}
            </motion.li>
          ))}
        </motion.ul>
      </nav>

      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <CtaButton
          label={t("navbar.ctaButton")}
          style={{
            padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
            borderRadius: "6px",
          }}
          onClick={() => handleScroll("contact")}
        />
      </motion.div>
    </motion.div>
  );
};

export default NavbarLarge;
