//GLOBAL ENGLISH

const englishTranslations = {
  navbar: {
    logo: "Amplify Marketing",
    navItems: {
      home: "Home",
      services: "Services",
      testimonial: "Testimonial",
      contact: "Contact",
    },
    ctaButton: "Get A Quote",
  },
  heroHeadingHighlight: "Amplify ",
  heroHeading: "Your Brand, One Click at a Time",
  heroText:
    "We help your businesses grow their online presence through strategic social media marketing, creative content, and data-driven results",
  heroButton: "Contact Now",
  topClientHead: "Our Top Clients",
  topClientText: "We have been working with over 500+ clients",
  communityHead: "Using our experience to make your digital experience ",
  communityHeadHighlight: "better",
  communityText: "What services does Amplify Marketing offer?",
  communityCard: {
    card1: {
      title: "Digital Strategy",
      description:
        "We develop custom digital strategies that drive measurable growth. We analyze your market, competitors, and audience to craft a plan that elevates your brand",
    },
    card2: {
      title: "User Experience",
      description:
        "We focus on creating seamless experiences that keep your customers engaged. Our team optimizes every interaction to ensure your digital platforms deliver value",
    },
    card3: {
      title: "Digital Design",
      description:
        "Our design experts bring your brand to life with visually compelling and innovative digital designs. We’ll help you stand out with unique branding and solution that resonate with you",
    },
    card4: {
      title: "Development",
      description:
        "We specialize in building powerful, efficient websites and applications tailored to your business goals. From clean code to scalable solutions, we ensure your digital presence is fast and reliable",
    },
  },
  chooseHeading: "Why Choose Us?",
  chooseText:
    "At Amplify Marketing, we provide tailored digital solutions designed to drive real growth for your business. With a focus on results, we use data and creativity to craft strategies that elevate your brand and engage your audience. Our team works closely with you to ensure seamless collaboration while delivering powerful design, user-friendly experiences, and scalable development solutions. From strategy to execution, we offer full-service support to help your brand stand out in the competitive digital landscape. Choose Amplify Marketing, and let us help you amplify your online presence.",
  chooseButton: "Contact Us",

  statsHeading: "Helping your business ",
  statsHeadingHighlight: "pop up on every radar",
  statsText: "We boost your business’ online presence",
  stat1: "Users Engaged",
  stat2: "Satisfied Clients",
  stat3: "Ad Campaigns",
  stat4: "Websites",

  processHead: "How Can We Help You?",
  processText: "Let's do great work together",
  process1: "Update content on my Website",
  process2: "Improve User Experience",
  process3: "Request Free Website Review",
  process4: "Improve your SEO Rankings",

  helpHead: "How Can We Help You?",
  helpText:
    "At Amplify Marketing, we’re here to help your business thrive in the digital world. Whether you're looking to grow your online presence, enhance customer engagement, or create a stunning digital experience, we’ve got you covered. Our team develops personalized strategies that align with your goals, from crafting digital campaigns that boost visibility to designing intuitive user experiences that keep customers coming back. We handle everything—from creative design and seamless website development to ongoing optimization—so you can focus on what you do best while we help amplify your success.",
  helpButton: "Contact Us",

  testimonialHead:
    "Don't take our word for it. See what customers are saying about us.",
  testimonial: {
    test1: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
    test2: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
    test3: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
    test4: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
    test5: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
    test6: {
      review:
        "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulumid ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.",
      name: "Coriss Ambady",
      role: "Financial Analyst",
    },
  },

  commUpdateHead: "Sharing Is The New Marketing",
  commUpdateText:
    "The Amplify Marketing blog is the best place to read about the latest marketing insights, trends and more. See who's joining the community, read about how our businesses are improving their online presence and lot's more.​",
  commUpdate: {
    blog1: "Creating Streamlined Safeguarding Processes with OneRen",
    blog2:
      "What are your safeguarding responsibilities and how can you manage them?",
    blog3: "Revamping the Membership Model with Triathlon Australia",
    readMore: "Read More",
  },

  contactHead: "Contact Us",
  emailPlaceholder: "Email Address",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
  contactButton: "Get A Quote",

  footerLogo: "Amplify Marketing",
  footerText: "Copyright © 2024 Amplify Marketing ltd.",
  footerText2: "All rights reserved",

  company: {
    companyHead: "Company",
    aboutUs: "About Us",
    blog: "Blog",
    contactUs: "Contact Us",
    pricing: "Pricing",
    testimonials: "Testimonials",
  },
  support: {
    supportHead: "Support",
    helpCenter: "Help center",
    terms: "Terms of service",
    legal: "Legal",
    privacy: "Privacy policy",
    status: "Status",
  },
  upToDate: {
    upToDateHead: "Stay up to date",
    placeholder: "Your email address",
  },
};

export default englishTranslations;
