//DESKTOP | LAPTOP | TABLET
import React from "react";
import Hero from "./hero/hero";

const HeaderLarge = () => {
  return (
    <div className="overflow-x-hidden relative">
      <Hero />
    </div>
  );
};

export default HeaderLarge;
