export const lightColors = {
  primary: "#000000",
  background: "#FFFFFF",
  heroColor: "#F5F7FA",
  logoColor: "#263238",
  navColor: "#18191F",
  mainGreen: "#4CAF4F",
  mainHeading: "#4D4D4D",
  mainText: "#717171",
  lightGreen: "#E8F5E9",
  reviewText: "#60697B",
  testnameText: "#343F52",
  inputBg: "#D7D7D7",
  inputText: "#37474F",
  iconBg: "#3c464c",
  // Add more colors as needed
};

export const darkColors = {
  primary: "#FFFFFF",
  background: "#000000",
  buttonText: "#000000",
  // Add more colors as needed
};

export const getColors = (isDarkMode) =>
  isDarkMode ? darkColors : lightColors;
