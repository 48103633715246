import React, { useState } from "react";
import spacings from "../../theme/spacings";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";
import { assetImages } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import CtaButton from "../buttons/cta-button/cta-button";
import { motion } from "framer-motion";

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  return (
    <motion.div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.heroColor,
      }}
      className="grid lg:grid-cols-2 grid-cols-1 gap-4 overflow-x-hidden"
      id="contact"
      whileInView={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }} // Animation happens only once when in view
    >
      <div className="lg:block hidden">
        <motion.img
          src={assetImages.contactImage}
          alt=""
          width={400}
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 50 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }} // Animation happens only once when in view
        />
      </div>

      <motion.div
        className="flex flex-col gap-6 lg:text-start text-center"
        whileInView={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }} // Animation happens only once when in view
      >
        <h3
          style={{
            color: colors.mainText,
            fontSize: fontSizes.heading2,
          }}
          className="font-semibold"
        >
          {t("contactHead")}
        </h3>

        <form
          action="mailto:services@dijinx.com"
          className="flex flex-col gap-6"
        >
          <motion.input
            type="email"
            required
            placeholder={t("emailPlaceholder")}
            className="p-3 rounded-lg placeholder:text-slate-600"
            style={{
              backgroundColor: colors.inputBg,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }} // Animation happens only once when in view
          />
          <motion.input
            type="text"
            required
            placeholder={t("subjectPlaceholder")}
            className="p-3 rounded-lg placeholder:text-slate-600"
            style={{
              backgroundColor: colors.inputBg,
            }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }} // Animation happens only once when in view
          />
          <motion.textarea
            placeholder={t("messagePlaceholder")}
            required
            className="p-3 rounded-lg placeholder:text-slate-600"
            style={{
              backgroundColor: colors.inputBg,
            }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={6}
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }} // Animation happens only once when in view
          ></motion.textarea>

          <div className="lg:w-44">
            <motion.div
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }} // Animation happens only once when in view
              whileHover={{ scale: 1.05 }}
            >
              <CtaButton
                label={t("contactButton")}
                style={{
                  padding: `${spacings.topBottomCta}px ${spacings.leftRightCta}px`,
                  borderRadius: "6px",
                  width: 200,
                  margin: "auto",
                }}
              />
            </motion.div>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default ContactForm;
