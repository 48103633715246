const spacings = {
  topBottomSpacing: 30,
  topBottomSpacingMobile: 40,
  topBottomSpacingNavMobile: 30,
  leftRightSpacing: 80,
  leftRightSpacingMobile: 20,
  topBottomCta: 10,
  leftRightCta: 20,
  // Add more spacings as needed
};

export default spacings;
