const assetIcons = {
  arrowRight:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/arrow-right.svg",
  facebook: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/facebook.svg",
  instagram:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/instagram.svg",
  logo: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/logo.svg",
  twitter: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/twitter.svg",
  development:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/offerings/development.svg",
  digitalDesign:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/offerings/digital-design.svg",
  digitalStrategy:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/offerings/digital-strategy.svg",
  userExperience:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/offerings/user-experience.svg",
  adCampaigns:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/stats/ad-campaigns.svg",
  satisfiedClients:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/stats/satisfied-clients.svg",
  usersEngaged:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/stats/users-engaged.svg",
  websites:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/stats/websites.svg",
  menuBar: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/menu.svg",
  close: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/close.svg",
  send: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/send.svg",
  dribble: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/dribbble.svg",
  youtube: "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/youtube.svg",
  footerLogo:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/svgs/logo-footer.svg",
  // Add more as needed
};

const assetImages = {
  heroImage: "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/hero.png",
  questionImage:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/question.png",
  growthImage:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/growth.png",
  contactImage:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/contact.png",
  blogImage1:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/blogs/1.png",
  blogImage2:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/blogs/2.png",
  blogImage3:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/blogs/3.png",
  logoImage1:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-2.png",
  logoImage2:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-3.png",
  logoImage3:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-4.png",
  logoImage4:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-5.png",
  logoImage5:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-6.png",
  logoImage6:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-7.png",
  logoImage7:
    "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/clients/Logo-8.png",
  user: "https://d1uxhsybh3036x.cloudfront.net/marketing/pngs/user.png",
  // Add more as needed
};

const assetVideos = {
  heroVideo: "<URL>",
  // Add more as needed
};

export { assetIcons, assetImages, assetVideos };
