import React from "react";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { fontSizes } from "../../theme/font-sizes";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { assetIcons, assetImages } from "../../constants/assets";

const CommunityUpdate = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  const handleScroll = useSmoothScroll();

  const blogPosts = [
    {
      id: 1,
      title: t("commUpdate.blog1"),
      image: assetImages.blogImage1,
      link: "#",
    },
    {
      id: 2,
      title: t("commUpdate.blog2"),
      image: assetImages.blogImage2,
      link: "#",
    },
    {
      id: 3,
      title: t("commUpdate.blog3"),
      image: assetImages.blogImage3,
      link: "#",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
      className="flex flex-col gap-4 overflow-x-hidden"
    >
      <motion.h3
        style={{
          color: colors.mainHeading,
          fontSize: fontSizes.heading2,
        }}
        className="font-semibold text-center"
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1 },
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        viewport={{ once: true }}
      >
        {t("commUpdateHead")}
      </motion.h3>
      <motion.p
        style={{
          color: colors.mainText,
          fontSize: fontSizes.body,
        }}
        className="text-center max-w-2xl mx-auto"
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1 },
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        viewport={{ once: true }}
      >
        {t("commUpdateText")}
      </motion.p>

      <div className="max-w-7xl mx-auto px-4 pt-6 pb-0">
        <motion.div
          // className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 place-content-center place-items-center"
          className="flex justify-center items-center gap-6 xl:flex-nowrap flex-wrap"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {blogPosts.map((post) => (
            <motion.div
              key={post.id}
              variants={itemVariants}
              whileHover={{ y: -5 }}
              className="relative xl:min-h-[400px] md:min-h-[660px] min-[525px]:min-h-[470px] min-[500px]:min-h-[440px] min-[460px]:min-h-[400px] min-[420px]:min-h-[380px] min-h-[360px]"
            >
              <div className="relative rounded-2xl overflow-hidden w-full">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-full object-contain"
                />
              </div>
              <div
                className="absolute bottom-[5%] min-[869px]:bottom-3 left-0 right-0 rounded-2xl shadow-lg p-6 mx-8 mb-4"
                style={{
                  backgroundColor: colors.heroColor,
                }}
              >
                <h3
                  className="font-medium mb-4 text-center min-[869px]:min-h-[85px] xl:max-w-full sm:max-w-xs mx-auto"
                  style={{
                    color: colors.mainText,
                    fontSize: fontSizes.cardHeading,
                  }}
                >
                  {post.title}
                </h3>
                <Link
                  to={post.link}
                  className="flex items-center justify-center transition-colors font-semibold"
                  style={{
                    color: colors.mainGreen,
                    fontSize: fontSizes.body,
                  }}
                >
                  {t("commUpdate.readMore")}
                  <img
                    src={assetIcons.arrowRight}
                    alt=""
                    className="ml-2 w-4 h-4"
                  />
                </Link>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default CommunityUpdate;
