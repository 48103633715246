const socialRedirectUrls = {
  instagram: "https://www.dijinx.com/",
  twitter: "https://www.dijinx.com/",
  dribble: "https://www.dijinx.com/",
  youtube: "https://www.dijinx.com/",
  // Add more as needed
};

const urls = {
  dijinx: "https://www.dijinx.com/",
  // Add more as needed
};

export { socialRedirectUrls, urls };
