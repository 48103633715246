import React, { useRef, useEffect, useState } from "react";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import spacings from "../../theme/spacings";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../providers/theme-provider";
import useWindowSize from "../../hooks/use-window-size";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons } from "../../constants/assets";
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
  useInView,
} from "framer-motion";
import InlineSvg from "../inline-svg/inline-svg";
import { svgColors } from "../../theme/svg-colors";

const Stats = () => {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const { colors } = useTheme();

  const { t } = useTranslation();

  const paddingLeftRight = isMobile
    ? spacings.leftRightSpacingMobile
    : spacings.leftRightSpacing;

  const paddingTopBottom = isMobile
    ? spacings.topBottomSpacingMobile
    : spacings.topBottomSpacing;

  // Utility function for animated counters
  const useAnimatedCounter = (finalValue) => {
    const count = useMotionValue(0);
    const roundedCount = useTransform(count, (latest) => Math.floor(latest));
    const [displayCount, setDisplayCount] = useState(0);
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
      if (isInView) {
        // Animate from 0 to the final value
        animate(count, finalValue, { duration: 2, ease: "easeOut" });
        const unsubscribe = roundedCount.onChange((latest) =>
          setDisplayCount(latest)
        );
        return unsubscribe;
      }
    }, [isInView, count, finalValue, roundedCount]);

    return { displayCount, ref };
  };

  // Individual stats
  const stat1 = useAnimatedCounter(2245341);
  const stat2 = useAnimatedCounter(500);
  const stat3 = useAnimatedCounter(300);
  const stat4 = useAnimatedCounter(456);

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.heroColor,
      }}
      className="flex lg:flex-row flex-col justify-between items-center xl:gap-10 gap-6"
    >
      <motion.div
        className="flex flex-col gap-3"
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1 },
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        viewport={{ once: true }} // Ensure the animation only triggers once
      >
        <h3
          style={{
            color: colors.mainHeading,
            fontSize: fontSizes.heading2,
          }}
          className="font-semibold lg:max-w-md lg:text-start text-center leading-tight"
        >
          {t("statsHeading")}{" "}
          <span
            style={{
              color: colors.mainGreen,
            }}
          >
            {t("statsHeadingHighlight")}
          </span>
        </h3>

        <p
          style={{
            fontSize: fontSizes.body,
            color: colors.mainText,
          }}
          className="lg:text-start text-center"
        >
          {t("statsText")}
        </p>
      </motion.div>

      <div className="flex flex-col gap-8 xl:mr-6 lg:mr-4">
        <div className="grid grid-cols-2 xl:gap-20 lg:gap-10 md:gap-16 gap-8 items-center">
          <motion.div
            ref={stat1.ref}
            className="flex flex-row gap-3"
            whileInView={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            viewport={{ once: true }} // Ensure the animation only triggers once
          >
            <InlineSvg
              url={assetIcons.usersEngaged}
              pathColors={[{ index: 0, color: svgColors.greenColor }]}
              heightInp={38}
              widthInp={38}
              className="md:w-10 w-8"
            />
            <div className="flex flex-col">
              <h3
                style={{
                  color: colors.mainHeading,
                  fontSize: fontSizes.heading3,
                }}
                className="font-semibold w-32"
              >
                {stat1.displayCount.toLocaleString()}+
              </h3>
              <p
                style={{
                  color: colors.mainText,
                  fontSize: fontSizes.info,
                }}
              >
                {t("stat1")}
              </p>
            </div>
          </motion.div>

          <motion.div
            ref={stat2.ref}
            className="flex flex-row gap-3"
            whileInView={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            viewport={{ once: true }} // Ensure the animation only triggers once
          >
            <InlineSvg
              url={assetIcons.satisfiedClients}
              pathColors={[
                { index: 0, color: svgColors.greenColor },
                { index: 1, color: svgColors.greenColor },
              ]}
              heightInp={38}
              widthInp={38}
              className="md:w-10 w-8"
            />
            <div className="flex flex-col">
              <h3
                style={{
                  color: colors.mainHeading,
                  fontSize: fontSizes.heading3,
                }}
                className="font-semibold"
              >
                {stat2.displayCount}+
              </h3>
              <p
                style={{
                  color: colors.mainText,
                  fontSize: fontSizes.info,
                }}
              >
                {t("stat2")}
              </p>
            </div>
          </motion.div>
        </div>
        <div className="grid grid-cols-2 xl:gap-20 lg:gap-10 md:gap-16 gap-8 items-center">
          <motion.div
            ref={stat3.ref}
            className="flex flex-row gap-3"
            whileInView={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            viewport={{ once: true }} // Ensure the animation only triggers once
          >
            <InlineSvg
              url={assetIcons.adCampaigns}
              pathColors={[{ index: 0, color: svgColors.greenColor }]}
              heightInp={38}
              widthInp={38}
              className="md:w-10 w-8"
            />
            <div className="flex flex-col">
              <h3
                style={{
                  color: colors.mainHeading,
                  fontSize: fontSizes.heading3,
                }}
                className="font-semibold"
              >
                {stat3.displayCount}+
              </h3>
              <p
                style={{
                  color: colors.mainText,
                  fontSize: fontSizes.info,
                }}
              >
                {t("stat3")}
              </p>
            </div>
          </motion.div>

          <motion.div
            ref={stat4.ref}
            className="flex flex-row gap-3"
            whileInView={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            initial={{ opacity: 0, scale: 0.5 }}
            viewport={{ once: true }} // Ensure the animation only triggers once
          >
            <InlineSvg
              url={assetIcons.websites}
              pathColors={[
                { index: 0, color: svgColors.greenColor },
                { index: 1, color: svgColors.greenColor },
                { index: 2, color: svgColors.greenColor },
              ]}
              heightInp={38}
              widthInp={38}
              className="md:w-10 w-8"
            />
            <div className="flex flex-col">
              <h3
                style={{
                  color: colors.mainHeading,
                  fontSize: fontSizes.heading3,
                }}
                className="font-semibold"
              >
                {stat4.displayCount}+
              </h3>
              <p
                style={{
                  color: colors.mainText,
                  fontSize: fontSizes.info,
                }}
              >
                {t("stat4")}
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
